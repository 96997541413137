import React from 'react';
// import logo from './logo.svg';
// import './App.css';
import Test from './components/Test'
import Showcase from './components/Showcase'
import {Container} from 'react-bootstrap'

function App() {
  return (
     <Test />
  );
}

export default App;
